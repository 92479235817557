import { Link } from 'react-router-dom';
import { Dokument, DokumentStatusEnum, DokumentType } from '../../shared/lib/types';
import { DocumentCollapse } from '../../../../prototype/components/Collapse';
import { format, parseISO } from 'date-fns';
import { DownloadSimple, MicrosoftWordLogo, FilePdf, Trash } from '@phosphor-icons/react';

import Modal from '../../../modal/Modal';
import ModalChildContent from '../../../modal/ModalChildContent';

type DokumentMappeProps = {
    dokumenter: Array<Dokument>;
    status: string;
    onDeleteDokument(id: string): void;
    onDeleteDokumententer(id: string, pdfId: string): void;
    dokumentMappeId: string;
    navn: string;
    kundeId: string;
    veilederKanSigneres?: boolean;
};

export default function PdfDokumentCollapse(props: DokumentMappeProps) {
    var pdfDokumenter = props.dokumenter.filter((item) => item.dokumenttype === DokumentType.pdf && item.parentPdfId === null);

    return (
        <>
            {pdfDokumenter.map((pdfItem) => (
                <DocumentCollapse
                    key={props.dokumentMappeId}
                    isPdf
                    name={`${pdfItem.tittel} (${props.dokumenter.filter((dok) => dok.parentPdfId === pdfItem.id).length} dokumenter)`}
                    onDeleteDokumententerClick={() => props.onDeleteDokumententer(props.dokumentMappeId, pdfItem.id)}
                    containerCss="active"
                    status={props.status}
                    date={format(parseISO(pdfItem.opprettetUtc), 'dd.MM.yyyy HH:mm')}
                    downloadUrl={`/fil/lastneddokument/${props.kundeId}/${pdfItem.id}`}
                    sendEpostUrl={`/kunder/send-epost/${props.kundeId}/${pdfItem.id}/${pdfItem.tittel}`}
                    sendSignUrl={
                        props.veilederKanSigneres &&
                        pdfItem.status.text === DokumentStatusEnum.gjeldende &&
                        pdfItem.signeringsstatus.text !== DokumentStatusEnum.signert
                            ? `/kunder/send-epost-signering/${props.kundeId}/${pdfItem.id}/${pdfItem.tittel}`
                            : null
                    }
                    signertDokumentUrl={
                        pdfItem.signeringsstatus.text === DokumentStatusEnum.signert
                            ? `/fil/lastneddokument/signert/${props.kundeId}/${pdfItem.id}`
                            : null
                    }
                >
                    {props.dokumenter
                        .filter((dok) => dok.parentPdfId === pdfItem.id)
                        .map((item: Dokument, index: number) => (
                            <DokumentRow
                                type={item.dokumenttype}
                                dokument={item}
                                onDeleteDokument={props.onDeleteDokument}
                                kundeId={props.kundeId}
                                index={index}
                            />
                        ))}
                </DocumentCollapse>
            ))}
        </>
    );
}

type RowProps = {
    dokument: Dokument;
    onDeleteDokument(id: string): void;
    kundeId: string;
    index: number;
    containerCss?: string;
    type: string;
};

const DokumentRow = (props: RowProps) => {
    const { id, tittel } = props.dokument;

    return (
        <div key={props.index} className={`container ${props.containerCss ?? ''}`}>
            <div className="dokument-body">
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="d-flex align-items-center gap-15">
                        {props.type === DokumentType.pdf ? <FilePdf id="icon" size={38} /> : <MicrosoftWordLogo id="icon" size={38} />}
                        {tittel}
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 dokument-action d-flex justify-content-end align-items-center">
                    <Link to={`/kunder/send-epost/${props.kundeId}/${id}/${tittel}`} className="btn btn--secondary btn--sm">
                        Send på epost
                    </Link>
                    <a
                        href={`/fil/lastneddokument/${props.kundeId}/${id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn--primary btn--sm icon-download"
                    >
                        <DownloadSimple id="icon" size={28} />
                    </a>
                    <Modal
                        useIcon
                        icon={<Trash id="icon" size={28} />}
                        triggerText="Slett"
                        triggerClassName="btn btn--secondary btn--sm icon-trash"
                        footer={
                            <>
                                <button className="btn btn--secondary btn--sm" data-bs-dismiss="modal" onClick={() => props.onDeleteDokument(id)}>
                                    Slett
                                </button>
                                <button className="btn btn--primary btn--sm" data-bs-dismiss="modal">
                                    Avbryt
                                </button>
                            </>
                        }
                        children={
                            <ModalChildContent
                                melding={`Er du sikker på at du vil slette dokumentet "${tittel}"?`}
                                tittel="Slette generert dokument"
                            />
                        }
                    ></Modal>
                </div>
            </div>
        </div>
    );
};
