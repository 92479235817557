import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { removeSpaces } from '../../../shared/lib/utils';
import api from '../shared/lib/api';
import { Dokument, DokumentOversikt } from '../shared/lib/types';
import DokumentCollapse from './DokumentCollapse';
import TilbakeTil from './Tilbake';
import Spinner from '../spinner/Spinner';
import FeilmeldingSide from '../feilside/FeilmeldingSide';
import toast from 'react-hot-toast';
import SelectInput, { ISelectInputOption } from '../../form/SelectInput';
import { InputElementEnum } from '../../../shared/lib/types';
import _ from 'lodash';

type TParams = {
    kundeId: string;
};

export default function GenererteDokumenter() {
    const { kundeId } = useParams<TParams>();
    const [dokumenter, setDokumenter] = useState<Array<DokumentOversikt>>();
    const [kunde, setKunde] = useState<string>();
    const [egetforetak, setEgetforetak] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);
    const [valgtDokumentStatus, setValgtDokumentStatus] = useState<string>('');
    const nedtrekksmenyRekkefolge: Array<string> = ['Kladd', 'Venter på signering', 'Gjeldende / Signert', 'Tidligere / Signert'];
    const [digitalSignering, setDigitalSignering] = useState<boolean>(false);
    const onDeleteDokumentMappe = (dokumentId: string) => {
        setLoading(true);

        if (egetforetak) {
            api.deleteDokumentMappeForEgetRegnskapsforetak(dokumentId)
                .then(() => {
                    const filteredDokumenter = dokumenter.filter((item: DokumentOversikt) => item.id !== dokumentId);
                    setDokumenter(filteredDokumenter);
                })
                .finally(() => setLoading(false));
        } else {
            api.deleteDokumentMappeForKunde(kundeId, dokumentId)
                .then(() => {
                    const filteredDokumenter = dokumenter.filter((item: DokumentOversikt) => item.id !== dokumentId);
                    setDokumenter(filteredDokumenter);
                })
                .finally(() => setLoading(false));
        }
    };

    const onDeleteDokument = (dokumentId: string) => {
        setLoading(true);

        if (egetforetak) {
            api.deleteDokumentForEgetRegnskapsforetak(dokumentId)
                .then(() => filtrerDokumenter(dokumentId))
                .catch(() => toast.error('Kunne ikke slette dokumentet'))
                .finally(() => setLoading(false));
        } else {
            api.deleteKundeDokument(kundeId, dokumentId)
                .then(() => filtrerDokumenter(dokumentId))
                .catch(() => toast.error('Kunne ikke slette dokumentet'))
                .finally(() => setLoading(false));
        }
    };

    const onDeletePdfDokumenter = (dokumentMappeId: string, pdfId: string) => {
        setLoading(true);
        api.deletePdfDokumenter(dokumentMappeId, pdfId, kundeId)
            .then(() => filtrerPdfDokumenter(pdfId))
            .catch(() => toast.error('Kunne ikke slette dokumentet'))
            .finally(() => setLoading(false));
    };

    const filtrerPdfDokumenter = (pdfId: string) => {
        const filteredDokumentOversikt = dokumenter.map((item: DokumentOversikt) => {
            const dokumenter = [...item.dokumenter];
            const filteredDokumenter = dokumenter.filter((dokument: Dokument) => dokument.parentPdfId !== pdfId);

            return {
                ...item,
                dokumenter: filteredDokumenter.filter((item) => item.id !== pdfId),
            };
        });

        setDokumenter(filteredDokumentOversikt);
    };

    const onDeleteDokumenter = (dokumentMappeId: string, status: string) => {
        setLoading(true);

        api.deleteDokumenter(dokumentMappeId, status, kundeId)
            .then(() => {
                if (egetforetak) {
                    api.getDokumenterForEgetRegnskapsforetak().then((res) => setDokumenter(res));
                } else {
                    api.getDokumenterForKunde(kundeId).then((res) => setDokumenter(res));
                }
            })
            .catch(() => toast.error('Kunne ikke slette dokumentet'))
            .finally(() => setLoading(false));
    };

    const filtrerDokumenter = (dokumentId: string) => {
        const filteredDokumentOversikt = dokumenter.map((item: DokumentOversikt) => {
            const dokumenter = [...item.dokumenter];
            const filteredDokumenter = dokumenter.filter((dokument: Dokument) => dokument.id !== dokumentId);

            return {
                ...item,
                dokumenter: filteredDokumenter,
            };
        });
        setDokumenter(filteredDokumentOversikt);
    };

    const sorterMenyvalgEtterRekkefolge = (a: ISelectInputOption, b: ISelectInputOption): number => {
        const aIndex: number = nedtrekksmenyRekkefolge.indexOf(a.text);
        const bIndex: number = nedtrekksmenyRekkefolge.indexOf(b.text);

        // Handle cases where status is not found in nedtrekksmenyRekkefolge
        if (aIndex === -1 && bIndex === -1) return 0;
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;

        // Sort by index
        return aIndex - bIndex;
    };

    const getInputOptions = (): Array<ISelectInputOption> =>
        nedtrekksmenyRekkefolge.map((status: string) => ({ text: status, value: status } as ISelectInputOption)).sort(sorterMenyvalgEtterRekkefolge);

    useEffect(() => {
        (() => {
            Promise.all([
                api.getInnloggetBruker().then(async (res) => {
                    if (res.regnskapsforetakId === kundeId) {
                        const [dokumenter, digitalSignering] = await Promise.all([
                            api.getDokumenterForEgetRegnskapsforetak(),
                            api.getDigitalSigneringForetak(),
                        ]);
                        setDokumenter(dokumenter);
                        setEgetforetak(true);
                        setKunde(res.regnskapforetakNavn);
                        setDigitalSignering(digitalSignering);
                    } else {
                        const [kunde, dokumenter, digitalSignering] = await Promise.all([
                            api.getKunde(kundeId).then(),
                            api.getDokumenterForKunde(kundeId),
                            api.getDigitalSigneringForetak(),
                        ]);
                        setKunde(kunde.navn);
                        setDokumenter(dokumenter);
                        setDigitalSignering(digitalSignering);
                    }
                }),
            ])
                .catch((error) => {
                    setServerErrorCode(error.status);
                })
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Dokumentarkiv';
        })();
    }, [kundeId]);

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/kunder" />;
    }

    return (
        <div className="genererte-dokumenter">
            <div className="page-header">
                <div className="container">
                    <div className="col-md-6 d-flex align-items-center">
                        <span className={`icon ${egetforetak ? 'icon-eget-regnskapsforetak' : 'icon-kundenavn-stor'}`} />
                        <h2>{kunde && kunde}</h2>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end dokumenter-info">
                        <span>Dokumentarkiv</span>
                    </div>
                </div>
            </div>
            <TilbakeTil url={`/kunder/${removeSpaces(kundeId)}/kundeside`} tekst="Tilbake til kundeside" />
            <div className="page">
                <div className="container">
                    <div className="title-and-menu-container">
                        <h1>Dokumentarkiv</h1>
                        <div className="col-lg-3">
                            {dokumenter && (
                                <SelectInput
                                    label="Dokumentstatus"
                                    firstOptionText="Alle"
                                    createFirstOption={true}
                                    name="dokumentstatus-select"
                                    value={valgtDokumentStatus}
                                    options={getInputOptions()}
                                    focus=""
                                    type={InputElementEnum.select}
                                    onInputChange={(name, value) => {
                                        setValgtDokumentStatus(value);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    {dokumenter && (
                        <DokumentCollapse
                            onDeleteDokumentMappe={onDeleteDokumentMappe}
                            onDeleteDokument={onDeleteDokument}
                            onDeleteDokumenter={onDeleteDokumenter}
                            onDeletePdfDokumententer={onDeletePdfDokumenter}
                            dokumentOversikt={dokumenter}
                            isEgetRegnskapsforetak={egetforetak}
                            valgtDokumentStatus={valgtDokumentStatus}
                            statusList={nedtrekksmenyRekkefolge}
                            digitalSignering={digitalSignering}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
