import { Link } from 'react-router-dom';
import { Dokument, DokumentStatusEnum, DokumentType } from '../../shared/lib/types';
import { DocumentCollapse } from '../../../../prototype/components/Collapse';
import { format, parseISO } from 'date-fns';
import { DownloadSimple, FilePdf, MicrosoftWordLogo, Trash } from '@phosphor-icons/react';

import Modal from '../../../modal/Modal';
import ModalChildContent from '../../../modal/ModalChildContent';

type DokumentMappeProps = {
    dokumenter: Array<Dokument>;
    status: string;
    onDeleteDokument(id: string): void;
    onDeleteDokumententer(id: string, status: string): void;
    dokumentMappeId: string;
    navn: string;
    kundeId: string;
    date?: string;
};

export default function WordDokumentCollapse(props: DokumentMappeProps) {
    if (props.dokumenter.length === 0) return null;

    return props.dokumenter.length === 1 ? (
        <>
            {props.dokumenter.map((item: Dokument, index: number) => (
                <DokumentRow
                    type={item.dokumenttype}
                    containerCss="active"
                    dokument={item}
                    onDeleteDokument={props.onDeleteDokument}
                    kundeId={props.kundeId}
                    index={index}
                />
            ))}
        </>
    ) : (
        <DocumentCollapse
            key={props.dokumentMappeId}
            name={`${props.navn} (${props.dokumenter.length} dokumenter)`}
            onDeleteDokumententerClick={() => props.onDeleteDokumententer(props.dokumentMappeId, props.status)}
            containerCss="active"
            status={props.status === DokumentStatusEnum.tidligere ? 'Alle tidligere' : props.status}
        >
            {props.dokumenter.map((item: Dokument, index: number) => (
                <DokumentRow
                    type={item.dokumenttype}
                    dokument={item}
                    onDeleteDokument={props.onDeleteDokument}
                    kundeId={props.kundeId}
                    index={index}
                />
            ))}
        </DocumentCollapse>
    );
}

type RowProps = {
    dokument: Dokument;
    onDeleteDokument(id: string): void;
    kundeId: string;
    index: number;
    containerCss?: string;
    type: string;
};

const DokumentRow = (props: RowProps) => {
    const { id, tittel, status, opprettetUtc } = props.dokument;
    return (
        <div key={props.index} className={`container ${props.containerCss ?? ''}`}>
            <div className="dokument-body word-dokument">
                <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="d-flex align-items-center gap-15 word-dokument-icon">
                        {props.type === DokumentType.pdf ? <FilePdf id="icon" size={38} /> : <MicrosoftWordLogo id="icon" size={38} />}
                        {tittel}
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-4">
                    <div className="d-flex justify-content-between">
                        <span>{status.text}</span>
                        <span>{format(parseISO(opprettetUtc), 'dd.MM.yyyy HH:mm')}</span>
                    </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 dokument-action d-flex justify-content-end align-items-center">
                    <Link to={`/kunder/send-epost/${props.kundeId}/${id}/${tittel}`} className="btn btn--secondary btn--sm">
                        Send på epost
                    </Link>
                    <a
                        href={`/fil/lastneddokument/${props.kundeId}/${id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn--primary btn--sm icon-download"
                    >
                        <DownloadSimple id="icon" size={28} />
                    </a>
                    <Modal
                        useIcon
                        icon={<Trash id="icon" size={28} />}
                        triggerText="Slett"
                        triggerClassName="btn btn--secondary btn--sm icon-trash"
                        footer={
                            <>
                                <button className="btn btn--secondary btn--sm" data-bs-dismiss="modal" onClick={() => props.onDeleteDokument(id)}>
                                    Slett
                                </button>
                                <button className="btn btn--primary btn--sm" data-bs-dismiss="modal">
                                    Avbryt
                                </button>
                            </>
                        }
                        children={
                            <ModalChildContent
                                melding={`Er du sikker på at du vil slette dokumentet "${tittel}"?`}
                                tittel="Slette generert dokument"
                            />
                        }
                    ></Modal>
                </div>
            </div>
        </div>
    );
};
