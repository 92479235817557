import React, { useState } from 'react';
import Modal from '../../components/modal/Modal';
import { guid } from '../../shared/lib/utils';
import ModalChildContent from '../../components/modal/ModalChildContent';
import { DownloadSimple, FilePdf, PencilSimpleLine, Trash } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { DokumentStatusEnum } from '../../components/App/shared/lib/types';

export interface ICollapse {
    title: string;
    text: string;
}
export interface ICollapseChild {
    name: string;
    children?: React.ReactNode;
    containerCss?: string;
    toggleCss?: string;
    show?: boolean;
}

export interface IDokumentCollapse {
    name: string;
    children?: React.ReactNode;
    containerCss?: string;
    toggleCss?: string;
    show?: boolean;
    onDeleteDokumententerClick(): void;
    status?: string;
    date?: string;
    downloadUrl?: string;
    isPdf?: boolean;
    sendEpostUrl?: string;
    sendSignUrl?: string;
    signertDokumentUrl?: string;
}

export interface IDokumentCollapseWithChildren {
    name: string;
    onDeleteDokumentMappeClick(): void;
    children?: React.ReactNode;
    containerCss?: string;
    toggleCss?: string;
    show?: boolean;
}

export const CollapseWithChild = (props: ICollapseChild) => {
    const [id] = useState(`collapse_${guid()}`);

    return (
        <div className={`collapse-container ${props.containerCss ?? ''}`}>
            <a
                className={`collapse__toggle ${props.toggleCss ?? ''}`}
                data-bs-toggle="collapse"
                href={'#' + id}
                role="button"
                aria-expanded={props.show}
                aria-controls={id}
            >
                <span>{props.name}</span>
                <span className="icon icon-arrow"></span>
            </a>
            <div className={`collapse ${props.show ? ' show' : ''}`} id={id}>
                <div className="collapse__content">{props.children}</div>
            </div>
        </div>
    );
};

export const Collapse = (props: ICollapse) => {
    const [id] = useState(`collapse_${guid()}`);

    return (
        <div className="collapse-container">
            <a className="collapse__toggle" data-bs-toggle="collapse" href={'#' + id} role="button" aria-expanded="false" aria-controls={id}>
                <span>{props.title}</span>
                <span className="icon icon-arrow"></span>
            </a>
            <div className="collapse" id={id}>
                <div className="collapse__content" dangerouslySetInnerHTML={{ __html: props.text }}></div>
            </div>
        </div>
    );
};
export function DocumentCollapseWithChildren(props: IDokumentCollapseWithChildren) {
    const [id] = useState(`collapse_${guid()}`);
    return (
        <div className={`document-collapse-container ${props.containerCss ?? ''}`}>
            <div className="collapse-container-header">
                <div className="col-4">
                    <a
                        className={`collapse__toggle ${props.toggleCss ?? ''}`}
                        data-bs-toggle="collapse"
                        href={'#' + id}
                        role="button"
                        aria-expanded="false"
                        aria-controls={id}
                    >
                        <span className="icon-plus"></span>
                        <span className="collapse-title">{props.name}</span>
                    </a>
                </div>
                <div className="col-4 header-button">
                    <Modal
                        useIcon={true}
                        triggerText="Slett"
                        icon={<Trash id="icon" size={28} />}
                        triggerClassName="btn btn--secondary btn--sm icon-trash"
                        footer={
                            <>
                                <button className="btn btn--secondary btn--sm" data-bs-dismiss="modal" onClick={props.onDeleteDokumentMappeClick}>
                                    Slett
                                </button>
                                <button className="btn btn--primary btn--sm" data-bs-dismiss="modal">
                                    Avbryt
                                </button>
                            </>
                        }
                        children={
                            <ModalChildContent tittel="Slette generert dokument" melding="Er du sikker på at du vil slette dette dokumentet?" />
                        }
                    ></Modal>
                </div>
            </div>
            <div className="collapse" id={id}>
                <div className="collapse__content">{props.children}</div>
            </div>
        </div>
    );
}

export function DocumentCollapse(props: IDokumentCollapse) {
    const [id] = useState(`collapse_${guid()}`);

    return (
        <div className={`document-collapse-container ${props.containerCss ?? ''}`}>
            <div className="collapse-container-header">
                <div className="col-4">
                    <a
                        className={`collapse__toggle ${props.toggleCss ?? ''}`}
                        data-bs-toggle="collapse"
                        href={'#' + id}
                        role="button"
                        aria-expanded="false"
                        aria-controls={id}
                    >
                        <span className="icon-plus"></span>

                        {props.isPdf ? (
                            <>
                                <span className="collapse-title d-flex align-items-center gap-15">
                                    <FilePdf id="icon" size={38} />
                                    {props.name}
                                </span>
                            </>
                        ) : (
                            <span className="collapse-title">{props.name}</span>
                        )}
                    </a>
                </div>
                {props.status === DokumentStatusEnum.venterpaasignering ? (
                    <>
                        <div className="col-3">
                            <div className="d-flex justify-content-between">
                                <span>{props.status}</span>
                                {props.date ? <span>{props.date}</span> : null}
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="header-button dokument-status-waiting align-items-baseline">
                                <span>{props.status}...</span>
                                <PencilSimpleLine size={20} />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-3">
                            <div className="d-flex justify-content-between">
                                {props.status ? <span>{props.status}</span> : null}
                                {props.date ? <span>{props.date}</span> : null}
                            </div>
                        </div>
                        <div className="col-5 header-button">
                            {props.signertDokumentUrl && (
                                <a href={props.signertDokumentUrl} className="btn btn--primary btn--sm">
                                    Last ned signert dokument
                                </a>
                            )}
                            {props.sendSignUrl && (
                                <Link to={props.sendSignUrl} className="btn btn--secondary btn--sm">
                                    Send til signering
                                </Link>
                            )}
                            {props.sendEpostUrl && (
                                <Link to={props.sendEpostUrl} className="btn btn--secondary btn--sm">
                                    Send på epost
                                </Link>
                            )}
                            {props.downloadUrl && (
                                <a href={props.downloadUrl} target="_blank" rel="noreferrer" className="btn btn--primary btn--sm icon-download">
                                    <DownloadSimple id="icon" size={28} />
                                </a>
                            )}
                            <Modal
                                useIcon={true}
                                triggerText="Slett"
                                icon={<Trash id="icon" size={28} />}
                                triggerClassName="btn btn--secondary btn--sm icon-trash"
                                footer={
                                    <>
                                        <button
                                            className="btn btn--secondary btn--sm"
                                            data-bs-dismiss="modal"
                                            onClick={props.onDeleteDokumententerClick}
                                        >
                                            Slett
                                        </button>
                                        <button className="btn btn--primary btn--sm" data-bs-dismiss="modal">
                                            Avbryt
                                        </button>
                                    </>
                                }
                                children={
                                    <ModalChildContent
                                        tittel="Slette generert dokument"
                                        melding="Er du sikker på at du vil slette dette dokumentet?"
                                    />
                                }
                            ></Modal>
                        </div>
                    </>
                )}
            </div>
            <div className="collapse" id={id}>
                <div className="collapse__content">{props.children}</div>
            </div>
        </div>
    );
}
