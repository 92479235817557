import { useParams } from 'react-router-dom';
import { Dokument, DokumentOversikt, DokumentType } from '../shared/lib/types';
import { DocumentCollapseWithChildren } from '../../../prototype/components/Collapse';
import PdfDokumentCollapse from './Dokumenter/PdfDokumentCollapse';
import WordDokumentCollapse from './Dokumenter/WordDokumentCollapse';

type TProps = {
    dokumentOversikt: Array<DokumentOversikt>;
    isEgetRegnskapsforetak: boolean;
    valgtDokumentStatus: string;
    onDeleteDokumentMappe(id: string): void;
    onDeleteDokument(id: string): void;
    onDeletePdfDokumententer(dokumentMappeId: string, pdfId: string): void;
    onDeleteDokumenter(dokumentMappeId: string, status: string): void;
    statusList: Array<string>;
    digitalSignering?: boolean;
};

type TParams = {
    kundeId: string;
};

export default function DokumentCollapse(props: TProps) {
    const { kundeId } = useParams<TParams>();
    const isValgtDokumentStatus = (item: Dokument): boolean =>
        props.valgtDokumentStatus === '' ? true : item.status.text === props.valgtDokumentStatus;
    const isValgteDokumenter = (items: DokumentOversikt): boolean => items.dokumenter.filter(isValgtDokumentStatus).length > 0;

    return (
        <div className="dokument-container">
            <div className="dokument-header">Navn</div>
            {props.dokumentOversikt
                .filter(isValgteDokumenter)
                .map(({ id, navn, dokumenter, opprettetUtc, veilederKanSigneres }: DokumentOversikt) => {
                    return (
                        <DocumentCollapseWithChildren key={id} name={navn} onDeleteDokumentMappeClick={() => props.onDeleteDokumentMappe(id)}>
                            {props.valgtDokumentStatus ? (
                                <DokumentMappe
                                    dokumenter={dokumenter}
                                    status={props.valgtDokumentStatus}
                                    dokumentMappeId={id}
                                    navn={navn}
                                    kundeId={kundeId}
                                    onDeleteDokument={props.onDeleteDokument}
                                    onDeleteDokumententer={props.onDeleteDokumenter}
                                    onDeletePdfDokumententer={props.onDeletePdfDokumententer}
                                    date={opprettetUtc}
                                    veilederKanSigneres={props.digitalSignering && veilederKanSigneres}
                                />
                            ) : (
                                props.statusList.map((status: string) => (
                                    <DokumentMappe
                                        dokumenter={dokumenter}
                                        status={status}
                                        dokumentMappeId={id}
                                        navn={navn}
                                        kundeId={kundeId}
                                        onDeleteDokument={props.onDeleteDokument}
                                        onDeleteDokumententer={props.onDeleteDokumenter}
                                        onDeletePdfDokumententer={props.onDeletePdfDokumententer}
                                        date={opprettetUtc}
                                        veilederKanSigneres={props.digitalSignering && veilederKanSigneres}
                                    />
                                ))
                            )}
                        </DocumentCollapseWithChildren>
                    );
                })}
        </div>
    );
}

type DokumentMappeProps = {
    dokumenter: Array<Dokument>;
    status: string;
    onDeleteDokument(id: string): void;
    onDeletePdfDokumententer(id: string, pdfId: string): void;
    onDeleteDokumententer(id: string, status: string): void;
    dokumentMappeId: string;
    navn: string;
    kundeId: string;
    date: string;
    veilederKanSigneres?: boolean;
};

function DokumentMappe(props: DokumentMappeProps) {
    var filteredDokumenter = props.dokumenter.filter((item) => item.status.text.toLocaleLowerCase() === props.status.toLocaleLowerCase());

    if (filteredDokumenter.length === 0) {
        return null;
    }

    var filteredWordDokumenter = filteredDokumenter.filter((dok) => !dok.parentPdfId && dok.dokumenttype !== DokumentType.pdf);

    return (
        <>
            <PdfDokumentCollapse
                dokumenter={filteredDokumenter}
                status={props.status}
                dokumentMappeId={props.dokumentMappeId}
                navn={props.navn}
                kundeId={props.kundeId}
                onDeleteDokument={props.onDeleteDokument}
                onDeleteDokumententer={props.onDeletePdfDokumententer}
                veilederKanSigneres={props.veilederKanSigneres}
            />
            {filteredWordDokumenter.length > 0 && (
                <WordDokumentCollapse
                    dokumenter={filteredWordDokumenter}
                    status={props.status}
                    dokumentMappeId={props.dokumentMappeId}
                    navn={props.navn}
                    kundeId={props.kundeId}
                    onDeleteDokument={props.onDeleteDokument}
                    onDeleteDokumententer={props.onDeleteDokumententer}
                    date={props.date}
                />
            )}
        </>
    );
}
